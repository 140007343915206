import { h, render } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import moment from 'moment';

const Timer = () => {
  const [time, setTime] = useState(moment().format('HH:mm:ss'));

  useEffect(() => {
    const intervalID = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const tick = () => {
    setTime(moment().format('HH:mm:ss'));
  };

  return (
    <div class="date-time bg-black bg-opacity-20 p-2 text-white text-center rounded-md">
      <h3 className="text-8xl">{time}</h3>
      <div class=" border-b border-green-400"></div>
      <h3 className="tracking-widest text-2xl">
        {moment().format('MMMM DD, YYYY')}
      </h3>
    </div>
  );
};

export default Timer;
