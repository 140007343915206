import { h, render, Component } from "preact";
import { useEffect, useState } from "preact/hooks";

const Footer = ({ credits }) => {
  return (
    <div className="absolute center-it bottom-0 flex flex-col gap-4 footer-text pazaz">
      <div className="m-auto text-white readable-text flex gap-1 text-center">
        <p>
          Photo by
          {credits.user && (
            <a
              href={
                credits.selfLink + "?utm_source=homerun&utm_medium=referral"
              }
            >
              {" "}
              {credits.user}{" "}
            </a>
          )}
          on
          <a href="https://unsplash.com/?utm_source=homerun&utm_medium=referral">
            &nbsp;Unsplash
          </a>
        </p>
      </div>

      <div className="flex justify-center p-0 text-white">
        <div>
          <a
            className="readable-text hover-perso"
            target="_blank"
            href="https://homerun.fun/uninstallterms"
          >
            Uninstall
          </a>{" "}
          |{" "}
          <a
            className="readable-text hover-perso"
            target="_blank"
            href="https://homerun.fun/privacy"
          >
            Privacy policy
          </a>{" "}
          |{" "}
          <a
            className="readable-text hover-perso"
            target="_blank"
            href="https://homerun.fun/terms"
          >
            Terms &amp; conditions &nbsp;{" "}
          </a>
        </div>
        <div className="readable-text">
          {" "}
          All the rights reserved to © Axeite Media LLC
        </div>
      </div>
    </div>
  );
};

export default Footer;
