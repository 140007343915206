import { h, render, Component } from 'preact';
import Timer from './Timer';
import SearchIcon from '../images/search.svg';
import Logo from '../images/homerun.png';

const Main = () => {
  let link = 'https://search.homerun.fun';

  return (
    <div class=" w-full search-container flex flex-col justify-center items-center absolute top-1/2 left-1/2 ">
      <img src={Logo} alt="Homerun-logo" />
      <Timer />
      <div
        class=" mt-16 flex justify-center mx-4 my-auto field h-16"
        id="searchform"
      >
        <form class="flex" target="_blank" action={link}>
          <input type="hidden" name="id" value="d4b69cbf93d6802d1f47b23f8fb8b9545dec0963"></input>
          <input
            class="text-white block outline-none text-xl flex-1 pl-6 bg-black bg-opacity-50 rounded-l-full border-transparent border-2 placeholder-gray-300 placeholder-opacity-60 perso-1"
            type="text"
            name="q"
            id="searchterm"
            placeholder="Search..."
            required
            minlength="1"
            autofocus
          />
          <button
            class=" relative pr-3 w-28 h-18 focus:outline-none rounded-r-full border-transparent bg-black bg-opacity-50 border-l-2 perso-3 perso-1 petite"
            type="submit"
            id="changeSearch"
          >
            <img
              class=" float-right m-0 px-2 h-8"
              src={SearchIcon}
              id="search-icon"
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Main;
