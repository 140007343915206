import { h, render } from "preact";
import { useState, useEffect } from "preact/hooks";
import Service from "../js/Service";
import ImageSlider from "./ImageSlider";
import FeedIcon from "../images/feed.png";

const News = () => {
  const [news, setNews] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [selectedFeed, setSelectedFeed] = useState(
    localStorage.homerun_feed || "cbc"
  );

  useEffect(() => {
    if (!news) fetchFeeds(selectedFeed);
  });
  const fetchFeeds = (source) => {
    Service.get(`feeds/${source}`)
      .then((res) => {
        // console.log(res);
        setNews(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="news-section">
      <div className="fd-Menu" aria-hidden={toggle}>
        <h1 className="text-white marginalos">
          Source:{" "}
          <select
            className="text-black h-7 w-28 px-2 py-1 bg-green-400 rounded-md outline-none"
            onChange={(e) => {
              setSelectedFeed(e.target.value);
              fetchFeeds(e.target.value);
              localStorage.setItem("homerun_feed", e.target.value);
            }}
            id="settings-stories-source"
            value={selectedFeed}
          >
            <option value="cbc">CBC Sports</option>
            <option value="sport360">Sports 360</option>
            <option value="skeeda">Sports Keeda</option>
            <option value="snet">Sports Net</option>
          </select>
        </h1>
        {news && <ImageSlider feeds={news} slidesToShow={5} />}
      </div>
      <div className="feeds-btn absolute bottom-5 left-1/2 lefty">
        <button className="w-12" onClick={(e) => setToggle(!toggle)}>
          <img src={FeedIcon} alt="sports" />
        </button>
      </div>
    </div>
  );
};

export default News;
