import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import Main from "../components/Main";
import Sports from "../components/Sports";
import News from "../components/News";
import Service from "./Service";
import DefaultImage from "../images/background.jpeg";
import Footer from "../components/Footer";

const sportTitles = {
  cricket_test_match: "Cricket Test Match",
  cricket_odi: "Cricket One Day Internationals",
  americanfootball_ncaaf: "US College Football - NCAAF",
  americanfootball_nfl: "US Football - NFL",
  aussierules_afl: "Aussie Football - AFL",
  americanfootball_ncaaf: "NCAAF",
  baseball_mlb: "MLB",
  basketball_nba: "NBA",
  icehockey_nhl: "NHL",
  mma_mixed_martial_arts: "MMA",
  rugbyleague_nrl: "NRL",
  soccer_belgium_first_div: "Belgium First Div",
  soccer_brazil_campeonato: "Brazil Série A",
  soccer_denmark_superliga: "Denmark Superliga",
  soccer_efl_champ: "EFL Championship",
  soccer_england_league1: "Soccer - UK League 1",
  soccer_england_league2: "Soccer - UK League 2",
  soccer_epl: "EPL",
  soccer_finland_veikkausliiga: "Veikkausliiga - Finland",
  soccer_france_ligue_one: "Ligue 1 - France",
  soccer_germany_bundesliga: "Bundesliga - Germany",
  soccer_germany_bundesliga2: "Bundesliga 2 - Germany",
  soccer_japan_j_league: "J League",
  soccer_league_of_ireland: "League of Ireland",
  soccer_norway_eliteserien: "Eliteserien - Norway",
  soccer_spl: "Premiership - Scotland",
  soccer_sweden_allsvenskan: "Allsvenskan - Sweden",
  soccer_sweden_superettan: "Superettan - Sweden",
  soccer_uefa_european_championship: "UEFA Euro 2020",
  soccer_usa_mls: "MLS",
  tennis_atp_wimbledon: "ATP Wimbledon",
  tennis_wta_wimbledon: "WTA Wimbledon",
};
const App = () => {
  const [sports, setSports] = useState(null);

  const [credits, setCredits] = useState(() => {
    let c = localStorage.getItem("homerun_credits");
    if (!c) {
      return {
        user: "unsplash.com",
        link: "https://unsplash.com",
        selfLink: "https://unsplash.com/",
      };
    } else {
      return JSON.parse(c);
    }
  });

  const background = localStorage.homerun_image || DefaultImage;

  useEffect(() => {
    document.body.style.backgroundImage = `url(${background})`;
    if (!sports) {
      let jsonContent;
      try {
        jsonContent = JSON.parse(localStorage.homerun_sports);
      } catch (err) {}
      const sport = jsonContent;
      if (sport) {
        apply(sport);
      }
    }
  }, []);

  const apply = (selected) => {
    let data = [...selected];
    localStorage.setItem("homerun_sports", JSON.stringify(selected));
    data = data?.map((d) => {
      return d.value;
    });

    Service.get("sports")
      .then((response) => {
        let sportsList = response.filter((s) => data.includes(s.id));
        const games = [];
        for (let g of sportsList) {
          let game = { name: g.name, games: [] };

          for (let c of g.competitions) {
            game.games.push(...c.games);
          }
          games.push(game);
        }
        setSports(games);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div class="h-screen">
      <News />
      <Main />
      <Sports
        setCredits={setCredits}
        SaveButton={apply}
        sportTitles={sportTitles}
      />
      <Footer credits={credits} />
    </div>
  );
};

export default App;
