import { h, render } from "preact";
import { useState } from "preact/hooks";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../images/image.jpeg";

// import './ImageSlider.css';

// 2.
const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow broccoli" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow carrot" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const ImageSlider = ({ feeds, slidesToShow = 3 }) => {
  // 3.
  const [imageIndex, setImageIndex] = useState(0);

  // 4.
  const settings = {
    centerMode: true,
    infinite: true,
    lazyLoad: true,
    dots: false,
    speed: 200,
    slidesToShow: slidesToShow,
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const stringToHTML = (str) => {
    var dom = document.createElement("div");
    dom.innerHTML = str;
    // console.log('======feeds=====', dom.children[0]);
    return dom?.children[0]?.currentSrc || Image;
  };

  // 5.
  const templateFeeds = feeds.map((feed, idx) => {
    return (
      <div
        className={idx === imageIndex ? "activeSlide" : "slide"}
        key={feed.title}
      >
        <div
          onClick={() => (window.location = feed.url)}
          className="slideWrapper"
        >
          <div className="image-container relative text-center text-white rad">
            {feed.displayImage ? (
              <img src={feed.displayImage} alt="tag" />
            ) : feed.description ? (
              <img src={stringToHTML(feed.description)} alt="tag" />
            ) : (
              <img src={Image} alt="tag" />
            )}

            <div
              onClick={() =>
                typeof feed.url !== "object" ? (window.location = feed.url) : ""
              }
              class="centered"
            >
              {feed.title}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <Slider {...settings}>{templateFeeds}</Slider>;
};

export default ImageSlider;
