import { h, render, Component } from "preact";
import { useState, useEffect } from "preact/hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SettingIcon from "../images/settings.png";
import Service from "../js/Service";
import DefaultImage from "../images/background.jpeg";

const Sports = (props) => {
  const [toggle, setToggle] = useState(true);
  const [wallpapers, setWallpapers] = useState(null);
  const [background, setBackground] = useState(
    localStorage.homerun_image || DefaultImage
  );
  useEffect(() => {
    if (!wallpapers) getWallpapers();
  }, []);

  const getWallpapers = () => {
    Service.get("wallpaper")
      .then((response) => {
        let wallpaperData = response;
        setWallpapers(wallpaperData);
      })
      .catch((err) => console.log(err));
  };

  const handleRestoreWallpaper = () => {
    document.body.style.backgroundImage = `url(${DefaultImage})`;
    localStorage.setItem("homerun_image", DefaultImage);
  };

  return (
    <div className="sports-menu">
      <div className="wi-Menu" aria-hidden={toggle}>
        <div className=" wi-Menu_Row text-white ">Select Wallpaper</div>
        <div className=" wi-Menu_Row text-white ">
          <button
            className=" border-2 border-green-400 px-4 py-1 rounded-full hover:bg-green-400 mb-2 "
            onClick={handleRestoreWallpaper}
          >
            Restore Default Wallpaper
          </button>
        </div>
        <div className="max-h-96">
          <div className="grid grid-cols-2 w-full px-1 overflow-auto">
            {wallpapers &&
              wallpapers.map((m) => (
                <LazyLoadImage
                  alt={"wallpaper"}
                  effect="blur"
                  src={m?.urls?.thumb} // use normal <img> attributes as props
                  wrapperClassName={"wallpaper"}
                  threshold={10}
                  delayTime={500}
                  visibleByDefault={true}
                  useIntersectionObserver={true}
                  placeholderSrc={m.alt_description}
                  onMouseOver={() => {
                    document.body.style.backgroundImage = `url(${m?.urls?.full})`;
                  }}
                  onMouseOut={() => {
                    document.body.style.backgroundImage = `url(${
                      localStorage.homerun_image || background
                    })`;
                  }}
                  onClick={() => {
                    document.body.style.backgroundImage = `url(${m?.urls?.full})`;
                    localStorage.setItem("homerun_image", m?.urls?.full);
                    let credits = {
                      user:
                        m?.user?.first_name + " " + (m?.user?.last_name ?? ""),
                      selfLink: m?.user?.links.html,
                    };
                    localStorage.setItem(
                      "homerun_credits",
                      JSON.stringify(credits)
                    );
                    props.setCredits(credits);
                  }}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-20 right-8">
        <button
          onClick={(e) => setToggle(!toggle)}
          className="w-12 rounded-full bg-gradient-to-r from-green-700 via-green-400 to-green-200 p-2"
        >
          <img src={SettingIcon} alt="sports" />
        </button>
      </div>
    </div>
  );
};

export default Sports;
